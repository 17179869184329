import { Badge } from "@/components/ui/badge";

import { SiteHeader } from "@/routes/site-header";
import { Separator } from "@radix-ui/react-select";
import { ArrowDown, Filter } from "lucide-react";
import { Link } from "react-router-dom";

import { Footer } from "@/routes/footer";
import { LoadingWindow, LoadingPostWindow } from "@/routes/loading";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Skeleton } from "@/components/ui/skeleton";
import { API_URL } from "@/config";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TabsListWithOutInfo,
} from "@/components/ui/tabs";
import { ErrorPage, ExpiredSubscription } from "@/routes/error";
import { useNavigate } from "react-router-dom";
import {
  AccountOverlay,
  DesktopOverlay,
  MobileOverlay,
} from "@/routes/account-overlay";
import { Navbar } from "./navbar";
import { FilterComponent } from "./filter";
import { ArticleCard } from "./articles";
import TopStoriesWidget from "./top-stories-cmp";
import { NewsFilter } from "./tmp";
import { getHeader, getToken, removeAccessToken } from "./utils";
import { TestFilter } from "./test-filter";
// import FXFilter from "./fx";

function NewsItems({
  isLoading,
  onSetTopNews,
  path = "/api/v1/news/",
  filters = null,
  setFireHouse = null,
  expired = false,
  reversed = false,
}) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [expires, setExpired] = useState(true);
  const navigate = useNavigate();
  // let API_URL = process.env.REACT_APP_API_URL;
  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }
  let colors = [
    "#d722e1",
    "#14498A",
    "#831813",
    "#085B1E",
    "#7A6A98",
    "#915D25",
    "#5C636D",
    "#6b1480",
  ];
  colors = reversed ? colors.reverse() : colors;

  const convertFilters = () => {
    return btoa(JSON.stringify(filters));
  };

  // Function to fetch data
  const fetchData = async (page) => {
    setLoading(true);
    isLoading(true);
    const token = getToken();
    let header = getHeader();
    try {
      const response = await axios.get(
        `${API_URL}${path}?page=${page}&filters=${convertFilters()}`,
        header
      );

      setExpired(response.data.code && response.data.code == 40101);

      if (page == 1) {
        setData(response.data.results);
      } else {
        setData((prevData) => [...prevData, ...response.data.results]);
      }

      setHasMore(response.data.next || false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
    isLoading(false);
  };
  useEffect(() => {
    if (filters) {
      setPage(1);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
    }
  }, [filters]);

  useEffect(() => {
    fetchData(page);
  }, [page, filters]);

  const handleLoadMore = (event) => {
    event.preventDefault();
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const offsetHeight = document.documentElement.offsetHeight;

    if (offsetHeight - (scrollTop + windowHeight) <= 300 && !loading) {
      if (hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    setFireHouse.setFireHouse(setFireHouse.bool);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  if (expired && expires) {
    // removeAccessToken()
    // // navigate("/account/")
    return <ExpiredSubscription h1={`Subscription`} />;
  }

  if (data.length == 0) {
    return <ErrorPage h1="0 Article!!!" />;
  }
  return (
    <div className="fbt-main-wrapper col-xl-12">
      <div id="main-wrapper">
        <div className="main-section" id="main_content">
          <div className="blog-posts fbt-index-post-wrap flex flex-wrap gap-4">
            {data &&
              data.map((item, index) => (
                <ArticleCard key={index} article={item} />
              ))}
          </div>
          {loading && page > 1 && <LoadingPostWindow />}
        </div>
      </div>
    </div>
  );
}

function TabIndex({ filters = null, setFireHouse = null }) {
  const [topNews, setTopNews] = useState([]);
  const [topLoader, setTopLoader] = useState(true);
  const [activeTab, setActiveTab] = React.useState("feed"); // Default active tab

  const handleSetTopNews = (news) => {
    setTopNews(news);
  };
  return (
    <>
      <Tabs defaultValue="feed" className="w-full">
        <TabsListWithOutInfo className="w-full sticky top-[109px] md:top-[64px] lg:top-[64px] z-10 bg-white">
          <TabsTrigger
            onClick={() => setActiveTab("feed")} // Wrap in a function
            className={`w-full h-full ${activeTab === "feed" ? "bg-info" : ""}`}
            value="feed"
          >
            Feed
          </TabsTrigger>

          <TabsTrigger
            onClick={() => setActiveTab("firehouse")} // Wrap in a function
            className={`w-full h-full ${
              activeTab === "firehouse" ? "bg-info" : ""
            }`}
            value="firehouse"
          >
            Firehose
          </TabsTrigger>
        </TabsListWithOutInfo>

        <TabsContent className="py-4" value="feed">
          {topLoader && (
            <div className="flex w-full flex-row gap-2 pt-4">
              <LoadingPostWindow />
            </div>
          )}

          <NewsItems
            filters={filters}
            isLoading={setTopLoader}
            onSetTopNews={handleSetTopNews}
            setFireHouse={{ setFireHouse: setFireHouse, bool: false }}
            expired={true}
          ></NewsItems>
        </TabsContent>
        <TabsContent className="py-4" value="firehouse">
          {topLoader && (
            <div className="flex w-full flex-row gap-2 pt-4">
              <LoadingPostWindow />
            </div>
          )}
          <NewsItems
            filters={filters}
            path="/api/v1/news/firehouse/"
            isLoading={setTopLoader}
            onSetTopNews={handleSetTopNews}
            setFireHouse={{ setFireHouse: setFireHouse, bool: true }}
          ></NewsItems>
        </TabsContent>
      </Tabs>
    </>
  );
}

const ThreePaneLayout = ({
  setFilterConfig,
  setFireHouse,
  filters,
  firehouse,
}) => {
  return (
    <div className="flex w-full">
      {/* Left Sidebar */}
      <div
        style={{
          top: "64px",
          left: "5%",
          position: "fixed",
          overflowY: "auto",
          width: "20%",
          height: "calc(100vh - 64px)",
        }}
        className="p-4 hidden md:block left-sidebar"
      >
        <TestFilter setFilterConfig={setFilterConfig} firehouse={firehouse} />
      </div>

      {/* Main Content */}
      <div className="flex-1 centered-content cls pt-4 md:p-4 md:pt-0 bg-white border-r border-gray-300">
        <TabIndex setFireHouse={setFireHouse} filters={filters} />
      </div>

      {/* Right Sidebar */}
      <div
        style={{
          top: "64px",
          right: "5%",
          position: "fixed",
          overflowY: "auto",
          width: "20%",
          height: "calc(100vh - 64px)",
        }}
        className="p-4 hidden md:block right-sidebar"
      >
        <TopStoriesWidget />
      </div>
    </div>
  );
};

export function Index() {
  const [topNews, setTopNews] = useState([]);
  const [topLoader, setTopLoader] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [filters, setFilters] = useState(true);
  const [firehouse, setFireHouse] = useState(false);
  const token = getToken();
  const topStoriesRef = useRef(null);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  // Function to apply filters
  const applyFilters = (filters) => {
    console.log("Filters applied:", filters);
  };
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");

    if (!hasVisited) {
      setIsVisible(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleSetTopNews = (news) => {
    setTopNews(news);
  };
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isVisible]);
  // if (isVisible) {
  //   return (
  //     <>
  //       <div className="hidden lg:block">
  //         {isVisible && <DesktopOverlay setIsVisible={setIsVisible} />}
  //       </div>
  //       <div className="block lg:hidden">
  //         {isVisible && <MobileOverlay setIsVisible={setIsVisible} />}
  //       </div>
  //     </>
  //   );
  // }
  // else

  return (
    <>
      {/* {token ? (
        <Navbar firehouse={firehouse} setFilterConfig={setFilters} />
      ) : (
        <SiteHeader />
      )} */}

        

      <Navbar firehouse={firehouse} setFilterConfig={setFilters} />
      <div className="hidden lg:block">
          {isVisible && <DesktopOverlay setIsVisible={setIsVisible} />}
        </div>
        <div className="block lg:hidden">
          {isVisible && <MobileOverlay setIsVisible={setIsVisible} />}
        </div>

      <div className="xl:container mx-auto px-0 md:px-3 sm:px-4 xl:px-2">
        <div className="flex flex-col md:flex-row gap-6">
          <ThreePaneLayout
            firehouse={firehouse}
            setFilterConfig={setFilters}
            setFireHouse={setFireHouse}
            filters={filters}
          />
        </div>
      </div>

      {/* <div className="xl:container mx-auto px-3 sm:px-4 xl:px-2">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-1/3 ml-4 hidden md:block">
            <div className="sticky top-64px">
              <TestFilter setFilterConfig={setFilters} firehouse={firehouse} />
            </div>
          </div>
          <div className="md:w-4/5 py-6">
            <div className="grid grid-cols-12 gap-x-4 "></div>
            <TabIndex setFireHouse={setFireHouse} filters={filters} />
          </div>
          <div className="w-1/3 ml-4 thia-sticky py-7 ">
            <TopStoriesWidget />
          </div>
        </div>
      </div> */}

      <hr></hr>

      <a
        href="#"
        className="back-top fixed p-3 rounded-full bg-teal-500 border border-teal-400 text-white hover:bg-teal-600 hover:border-teal-500 shadow-lg transition-all duration-300 ease-in-out right-4 bottom-4 block"
        aria-label="Scroll To Top"
      >
        <svg
          width="1.25rem"
          height="1.25rem"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          className="transition-all duration-300 ease-in-out"
        >
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 01.5.5v9a.5.5 0 01-1 0V4a.5.5 0 01.5-.5z"
            clipRule="evenodd"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.646 2.646a.5.5 0 01.708 0l3 3a.5.5 0 01-.708.708L8 3.707 5.354 6.354a.5.5 0 11-.708-.708l3-3z"
            clipRule="evenodd"
          ></path>
        </svg>
      </a>
    </>
  );
}
