import React, { useEffect, useState } from "react";
import {
  Menu,
  Search,
  Filter,
  SlidersHorizontal,
  Activity,
  Banknote,
  Newspaper as Feed,
  Bookmark,
  Settings,
  LogOut,
  Rss as Subscription,
  Home,
  Icon,
  LogInIcon,
  SigmaSquareIcon,
} from "lucide-react"; // Import icons
import { FilterComponent, StateAcordion } from "./filter";
import { SearchMenu } from "@/routes/search";
import { set } from "date-fns";
import axios from "axios";
import { API_URL } from "@/config";
import { Icons } from "@/components/ui/icon";
import { useNavigate } from "react-router-dom";
import {
  FeedsAccordion,
  SubjectsAccordion,
  PredefinedDatesAccordion,
} from "./filter";
import { getHeader, getToken, getWithExpiry, removeAccessToken, setWithExpiry } from "./utils";
import { DropDownMenuMe } from "./site-header";

const us_states = [
  (1, "Alabama"),
  (2, "Alaska"),
  (3, "Arizona"),
  (4, "Arkansas"),
  (5, "California"),
  (6, "Colorado"),
  (7, "Connecticut"),
  (8, "Delaware"),
  (9, "Florida"),
  (10, "Georgia"),
  (11, "Hawaii"),
  (12, "Idaho"),
  (13, "Illinois"),
  (14, "Indiana"),
  (15, "Iowa"),
  (16, "Kansas"),
  (17, "Kentucky"),
  (18, "Louisiana"),
  (19, "Maine"),
  (20, "Maryland"),
  (21, "Massachusetts"),
  (22, "Michigan"),
  (23, "Minnesota"),
  (24, "Mississippi"),
  (25, "Missouri"),
  (26, "Montana"),
  (27, "Nebraska"),
  (28, "Nevada"),
  (29, "New Hampshire"),
  (30, "New Jersey"),
  (31, "New Mexico"),
  (32, "New York"),
  (33, "North Carolina"),
  (34, "North Dakota"),
  (35, "Ohio"),
  (36, "Oklahoma"),
  (37, "Oregon"),
  (38, "Pennsylvania"),
  (39, "Rhode Island"),
  (40, "South Carolina"),
  (41, "South Dakota"),
  (42, "Tennessee"),
  (43, "Texas"),
  (44, "Utah"),
  (45, "Vermont"),
  (46, "Virginia"),
  (47, "Washington"),
  (48, "West Virginia"),
  (49, "Wisconsin"),
  (50, "Wyoming"),
  (51, "National"),
];

export const Navbar = ({
  setFilterConfig = null,
  firehouse = false,
  post = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedFeeds, setSelectedFeeds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [subscribedFeeds, setSubscribedFeeds] = useState([]);
  const [purchased, setPurchased] = useState(false);
  const [topStories, setTopStories] = useState(false);
  const [allFeeds, setAllFeeds] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [searchKeyword, setSearchKeyWord] = useState(null);
  const [filteredSubscribedFeeds, setFilteredSubscribedFeeds] = useState([]);
  const [data, setData] = useState(null);
  const token = getToken();
  const navigate = useNavigate();

  const addStates = (state) => {
    let canAdd = true;
    for (let i = 0; i < selectedStates.length; i++) {
      if (state[0] === selectedStates[i][0]) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedStates([...selectedStates, state]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const addOrRemoveState = (state) => {
    const exists = selectedStates.some((s) => s[0] === state[0]);
  
    if (exists) {
      // Remove the state if it already exists
      setSelectedStates(selectedStates.filter((s) => s[0] !== state[0]));
    } else {
      // Add the state if it doesn't exist
      setSelectedStates([...selectedStates, state]);
    }
  
    setSearchTerm(""); // Clear the search term
  };
  

  const removeStates = (state) => {
    setSelectedStates(selectedStates.filter((item) => item[0] !== state[0]));
  };

  function storeData(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  function getData(key) {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if data is already stored in sessionStorage
        const cachedData = sessionStorage.getItem("apiData");

        if (cachedData) {
          setData(JSON.parse(cachedData));
        } else {
          // Fetch data using Axios
          const response = await axios.get(
            `${API_URL}/api/v1/news/providers-state/`
          );
          setData(response.data);
          sessionStorage.setItem("apiData", JSON.stringify(response.data));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchAllFeeds = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/news/providers/`);
        setAllFeeds(response.data);
        storeData("all_feeds", response.data);
        setSubscribedFeeds(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchSubscription = async () => {
      try {
        let header = getHeader();
        const subscriptionResponse = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          header
        );
        setSubscribedFeeds(subscriptionResponse.data.feeds);
        storeData("feeds", subscriptionResponse.data.feeds);
      } catch (error) {
        console.error("Error during subscription request:", error);
      } finally {
      }
    };
    const fetchTags = async () => {
      try {
        const tags = await axios.get(`${API_URL}/api/v1/news/tags/`);
        setFilteredCategories(tags.data);
        setWithExpiry("categories", tags.data, 7 * 24 * 60 * 60 * 1000);
      } catch (error) {
        console.error("Error during tags request:", error);
      } finally {
      }
    };
    let ffeeds = getData("feeds");
    let all_feeds = getData("all_feeds");
    let categories = getWithExpiry("categories");

    if (!token) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    } else if (!firehouse) {
      if (ffeeds) {
        setSubscribedFeeds(ffeeds);
      } else {
        fetchSubscription();
      }
    } else if (firehouse) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    }

    if (categories == null || categories == undefined) {
      fetchTags();
    } else {
      setFilteredCategories(categories);
    }
    clearFilters();
  }, [firehouse]);

  const predefinedRanges = [
    {
      label: "Any",
      start: "",
      end: "",
    },
    {
      label: "Today",
      start: () => new Date().toISOString().split("T")[0],
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "Yesterday",
      start: () => getDateDaysAgo(2),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "1 Week Ago",
      start: () => getDateDaysAgo(7),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "Custom Date Range",
    },
  ];

  const addCategory = (category) => {
    let canAdd = true;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (category.alias === selectedCategories[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedCategories([...selectedCategories, category]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeCategory = (category) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };

  const addFeeds = (feed) => {
    let canAdd = true;
    for (let i = 0; i < selectedFeeds.length; i++) {
      if (feed.alias === selectedFeeds[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedFeeds([...selectedFeeds, feed]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const addOrRemoveFeed = (feed) => {
    const exists = selectedFeeds.some((f) => f.alias === feed.alias);
  
    if (exists) {
      // Remove the feed if it already exists
      setSelectedFeeds(selectedFeeds.filter((f) => f.alias !== feed.alias));
    } else {
      // Add the feed if it doesn't exist
      setSelectedFeeds([...selectedFeeds, feed]);
    }
  
    setSearchTerm(""); // Clear the search term
  };
  

  const removeFeeds = (feed) => {
    setSelectedFeeds(selectedFeeds.filter((item) => item.alias !== feed.alias));
  };

  const handlePredefinedRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (start == undefined && end == undefined) {
      setSelectedPredefinedRange("Custom date");
    } else if (start == "" && end == "") {
      setSelectedPredefinedRange("Any");
    } else {
      setSelectedPredefinedRange(
        predefinedRanges.find(
          (range) => range.start === start && range.end === end
        ).label
      );
    }
  };

  const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split("T")[0];
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsFilterVisible(false);
  };

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    setIsOpen(false);
  };

  const applyFilters = () => {
    setIsFilterVisible(!isFilterVisible);
    setIsOpen(false);
    if (setFilterConfig) {
      setFilterConfig({
        selectedFeeds: selectedFeeds,
        selectedCategories: selectedCategories,
        purchased: purchased,
        date: {
          startDate,
          endDate,
        },
        topStories: topStories,
        selectedStates: selectedStates,
        searchKeyword: searchKeyword,
      });
    }
  };
  useEffect(() => {
    if(setFilterConfig == null || setFilterConfig == undefined){
      return
    }
    setFilterConfig({
      selectedFeeds: selectedFeeds,
      selectedCategories: selectedCategories,
      purchased: purchased,
      selectedStates: selectedStates,
      date: {
        startDate,
        endDate,
      },
      topStories: topStories,
    });
  }, [
    selectedFeeds,
    selectedCategories,
    purchased,
    selectedStates,
    startDate,
    endDate,
    topStories,
  ]);

  function filterMappingsByState(states, mappings) {
    if (!Array.isArray(states) || !Array.isArray(mappings)) {
      console.error("Invalid input: states and mappings must be arrays.");
      return [];
    }

    const stateIds = new Set(
      states
        .map((state) => (Array.isArray(state) ? state[0] : null))
        .filter((id) => id !== null)
    );

    return mappings.filter((mapping) => {
      if (typeof mapping !== "object" || mapping === null) return false;
      return stateIds.has(mapping.state);
    });
  }

  function filterList(objects, mappings) {
    return mappings
      .map(({ alias, state }) => {
        // Destructure alias and state from object
        const matchedObject = objects.find((obj) => obj.alias === alias);
        return matchedObject ? { ...matchedObject, state } : null; // Include matched object and state
      })
      .filter((item) => item !== null); // Filter out any null values
  }

  useEffect(() => {
    if (selectedStates.length == 0) {
      setFilteredSubscribedFeeds(subscribedFeeds);
    } else {
      const filteredMapState = filterMappingsByState(selectedStates, data);
      const filteredMap = filterList(subscribedFeeds, filteredMapState);
      setFilteredSubscribedFeeds(filteredMap);
    }
  }, [selectedStates]);

  const clearFilters = () => {
    if (setFilterConfig) {
      setFilterConfig({});
    }
    setSearchKeyWord(null);
    setSelectedStates([]);
    setSelectedCategories([]);
    setSelectedFeeds([]);
    setStartDate(null);
    setEndDate(null);
    setTopStories(false);
    setPurchased(false);
    setSelectedPredefinedRange("Any");
  };
  const handleLogOut = () => {
    removeAccessToken();
    window.location.href = "/";
  };
  useEffect(() => {
    if (isFilterVisible) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isFilterVisible]);

  return (
    <header className="sticky top-0 z-50 bg-white shadow-lg">
      <a
        href="/"
        className="flex items-center justify-center space-y-2 sm:flex md:hidden"
      >
        <span className="font-bold">
          <img src="https://dev.newsne.ws/logo_nemesis.png" />
        </span>
      </a>
      <div className="responsive-padding mx-auto sm:px-6">
        <div className="flex justify-between items-center h-16">
          {
            <>
              {setFilterConfig && (
                <>
                  <div className="block items-center flex pl-2 test md:hidden">
                    <button
                      onClick={() => navigate("/")}
                      className="text-gray-800 hover:text-blue-600 focus:outline-none"
                    >
                      <Home className="w-5 h-5 mr-3" />
                    </button>
                  </div>
                </>
              )}
              {setFilterConfig && (
                <>
                  <div
                    style={{ cursor: "pointer" }}
                    className="hidden cursor md:flex items-center pl-2 test1"
                  >
                    <a href="/" className="font-bold">
                      <img src="https://dev.newsne.ws/logo_nemesis.png" />
                    </a>
                  </div>
                </>
              )}

              {setFilterConfig && (
                <>
                  <div className="block lg:hidden items-center flex">
                    <button
                      onClick={toggleFilter}
                      className="text-gray-800 hover:text-blue-600 focus:outline-none"
                    >
                      <SlidersHorizontal className="w-6 h-6" />
                    </button>
                  </div>
                </>
              )}
            </>
          }

          {!setFilterConfig && (
            <>
              <div className="hidden md:block">
                <a href="/">
                  <img
                    src="https://dev.newsne.ws/logo_nemesis.png" // Replace with the actual path to your logo
                    alt="Logo"
                    className="h-8 w-auto"
                  />
                </a>
              </div>
            </>
          )}

          <div className="flex-grow md:flex md:justify-center flex justify-center px-2">
            <SearchMenu />
          </div>

          {/* Menu Icon for Mobile - Right */}
          {token && (
            <div className="block md:hidden items-center flex">
              <button
                onClick={toggleMenu}
                className="text-gray-800 hover:text-blue-600 focus:outline-none"
              >
                <Menu className="w-6 h-6" />
              </button>
            </div>
          )}

          {!token && <DropDownMenuMe />}

          {token && (
            <nav className="hidden md:flex space-x-6 items-center">
              <a
                href="/dashboard/profile/"
                className="text-gray-800 hover:text-blue-600 transition-colors"
              >
                Activity
              </a>
              <a
                href="/dashboard/feed/"
                className="text-gray-800 hover:text-blue-600 transition-colors"
              >
                My Feed
              </a>
              <a
                href="/dashboard/transaction/history/"
                className="text-gray-800 hover:text-blue-600 transition-colors"
              >
                Bank
              </a>
              <a
                href="/dashboard/subscription/"
                className="text-gray-800 hover:text-blue-600 transition-colors"
              >
                Subscription
              </a>
              <a
                href="/book-mark/"
                className="text-gray-800 hover:text-blue-600 transition-colors"
              >
                Bookmarks
              </a>
              <a
                href="/dashboard/settings/"
                className="text-gray-800 hover:text-blue-600 transition-colors"
              >
                Settings
              </a>
              <a
                href="#"
                onClick={() => {
                  handleLogOut();
                }}
                className="text-gray-800 hover:text-red-600 transition-colors"
              >
                Logout
              </a>
            </nav>
          )}
        </div>
      </div>
      {isFilterVisible && (
        <div className="fixed left-0 w-2/3 md:w-[270px] bg-gray-100 border-t border-gray-300 z-50">
          <div className="bg-white p-4 h-[calc(100vh-110px)] overflow-y-auto">
            <h2 className="text-xl font-bold mb-4">Filter</h2>
            <div className="mb-3">
              {selectedStates && selectedStates.length > 0 && (
                <div>
                  <h3 className="text-lg font-semibold mb-2">Selected State</h3>
                  <div className="flex flex-wrap gap-2">
                    {selectedStates.map((state) => (
                      <span
                        key={state[0]}
                        className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                      >
                        {state[1]}
                        <button
                          className="ml-2 text-blue-600 hover:text-blue-800"
                          onClick={() => removeStates(state)}
                        >
                          &times;
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {selectedFeeds && selectedFeeds.length > 0 && (
                <div>
                  <h3 className="text-lg font-semibold mb-2">Selected Feeds</h3>
                  <div className="flex flex-wrap gap-2">
                    {selectedFeeds.map((feed) => (
                      <span
                        key={feed.alias}
                        className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                      >
                        {feed.display_name || feed.name}
                        <button
                          className="ml-2 text-blue-600 hover:text-blue-800"
                          onClick={() => removeFeeds(feed)}
                        >
                          &times;
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {selectedCategories && selectedCategories.length > 0 && (
                <div>
                  <h3 className="text-lg font-semibold mb-2">Selected Tags</h3>
                  <div className="flex flex-wrap gap-2">
                    {selectedCategories
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((category) => (
                        <span
                          key={category.alias}
                          className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                        >
                          {category.title.toUpperCase()}
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() => removeCategory(category)}
                          >
                            &times;
                          </button>
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
            <div className="space-y-1">
              {token && (
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value="false"
                    onChange={() => setPurchased(!purchased)}
                    checked={purchased}
                    className="mr-2"
                  />
                  Purchased
                </label>
              )}

              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value="false"
                  onChange={() => setTopStories(!topStories)}
                  checked={topStories}
                  className="mr-2"
                />
                Top Stories
              </label>

              <div className="space-y-1 relative">
                <input
                  type="text"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyWord(e.target.value)}
                  placeholder="Search keyword..."
                  className="w-full p-2 border border-gray-300 rounded-md mb-2"
                />
                {searchKeyword && (
                  <button
                    className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                    onClick={() => setSearchKeyWord("")}
                  >
                    &#x2715;
                  </button>
                )}
              </div>
              <PredefinedDatesAccordion
                selectedDateRange={selectedPredefinedRange}
                predefinedDateRanges={predefinedRanges}
                setDateRange={handlePredefinedRange}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
              />
              <StateAcordion
                selectedStates={selectedStates}
                addStates={addOrRemoveState}
                removeState={removeStates}
              />
              <FeedsAccordion
                selectedFeeds={selectedFeeds}
                subscribedFeeds={filteredSubscribedFeeds}
                addFeeds={addOrRemoveFeed}
                removeFeeds={removeFeeds}
              />

              <SubjectsAccordion
                selectedCategories={selectedCategories}
                filteredCategories={filteredCategories}
                addCategory={addCategory}
                removeCategory={removeCategory}
              />
            </div>
            
            {/* Apply Button (Sticky at the bottom) */}
            {/* <div className="sticky bottom-0 bg-white py-4">
              <div className="flex justify-between items-center">
                <button
                  onClick={clearFilters}
                  className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <button
                  onClick={applyFilters}
                  className="flex items-center px-4 py-2 bg-info text-white rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </button>
              </div>
            </div> */}
          </div>
        </div>
      )}

      {/* {isOpen && !token && (
        <div className="md:hidden bg-gray-100 p-4 border-t border-gray-300">
          <nav className="flex flex-col space-y-4">
            <a
              href="/login/"
              className="flex items-center py-1 px-2 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <LogInIcon className="w-5 h-5 mr-3" />
              Login
            </a>
            <a
              href="/signup/"
              className="flex items-center py-1 px-2 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <SigmaSquareIcon className="w-5 h-5 mr-3" />
              Sign Up
            </a>
          </nav>
        </div>
      )} */}

      {isOpen && token && (
        <div className="md:hidden bg-gray-100 p-4 border-t border-gray-300">
          <nav className="flex flex-col space-y-4">
            <a
              href="/"
              className="flex items-center py-1 px-2 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Home className="w-5 h-5 mr-3" />
              Home
            </a>
            <a
              href="/dashboard/profile/"
              className="flex items-center py-1 px-2 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Activity className="w-5 h-5 mr-3" />
              Activity
            </a>
            <a
              href="/dashboard/feed/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors active:bg-blue-200 active:text-blue-700"
            >
              <Feed className="w-5 h-5 mr-3" />
              My Feed
            </a>
            <a
              href="/dashboard/transaction/history/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Banknote className="w-5 h-5 mr-3" />
              Bank
            </a>
            <a
              href="/dashboard/subscription/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Subscription className="w-5 h-5 mr-3" />
              Subscription
            </a>
            <a
              href="/book-mark/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Bookmark className="w-5 h-5 mr-3" />
              Bookmarks
            </a>
            <a
              href="/dashboard/settings/"
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-blue-100 hover:text-blue-600 transition-colors"
            >
              <Settings className="w-5 h-5 mr-3" />
              Settings
            </a>
            <a
              href="#logout"
              onClick={() => {
                handleLogOut();
              }}
              className="flex items-center py-1 px-1 rounded-md text-gray-800 hover:bg-red-100 hover:text-red-600 transition-colors"
            >
              <LogOut className="w-5 h-5 mr-3" />
              Logout
            </a>
          </nav>
        </div>
      )}
    </header>
  );
};
