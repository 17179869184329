import { ArrowDown, PlusCircle } from "lucide-react";

import { Button } from "@/components/ui/button";
// import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

// import { AlbumArtwork } from "./components/album-artwork"
import { Menu } from "./menu";
// import { PodcastEmptyPlaceholder } from "./components/podcast-empty-placeholder"
import { Sidebar } from "./sidebar";
// import { listenNowAlbums, madeForYouAlbums } from "./data/albums"
// import { playlists } from "./data/playlists"
import {SettingsNotificationsPage} from "./notification"
import {PaymentMethod} from "./payments"
import { useLocation, useNavigate } from 'react-router-dom';
import { FeederChart } from "@/routes/dashboard/feed-chart";
import {ActivityComp} from "@/routes/dashboard/activity"
import { FeedList } from "./feed";
import { SubscriptionPage } from "@/routes/dashboard/subscription";
import { Transaction } from "./transaction";
import { BookMarks } from "./bookmark";
import { SettingsPage } from "./settings";
import { getToken } from "../utils";

export function Dashbord() {
  const location = useLocation()
  const token = getToken()
  const navigate = useNavigate()
  if(!token){
    navigate('/login/')
  }
  return (
    <>
      <div className="md:block">
        {/* <Menu /> */}
        <div className="border-t">
          <div className="bg-background">
            <div className="grid lg:grid-cols-5">
              <Sidebar className="lg:block" />
              <div className="col-span-3 lg:col-span-4 lg:border-l">
                <div className="h-full px-4 py-6 lg:px-8">
                  <div className="space-y-6">
                    <div>
                    </div>
                    { (location.pathname == '/dashboard/profile/') && <ActivityComp></ActivityComp>}
                    { (location.pathname == '/dashboard/feed/') && <FeedList></FeedList>}
                    { (location.pathname == '/dashboard/subscription/') && <SubscriptionPage></SubscriptionPage>}
                   
                    { (location.pathname == '/dashboard/notification/') && <SettingsNotificationsPage></SettingsNotificationsPage>}
                    { (location.pathname == '/dashboard/payment/') && <PaymentMethod></PaymentMethod>}
                    { (location.pathname == '/dashboard/transaction/history/') && <Transaction></Transaction>}
                    { (location.pathname == '/dashboard/bookmark/') && <BookMarks />}
                    { (location.pathname == '/dashboard/settings/') && <SettingsPage />}
                    {/* <Separator /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
