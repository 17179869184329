import { FeederChart } from "@/routes/dashboard/feed-chart";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Newspaper, EyeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "@/config";

import { CreditCard } from "lucide-react";
import { getHeader, getToken } from "../utils";

const AppearanceSettings = () => {
  const [filterSensitive, setFilterSensitive] = useState(false);
  const [hideImages, setHideImages] = useState(false);
  const [suppressAudio, setSuppressAudio] = useState(false);

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold mb-4">Appearance Settings</h2>

      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={filterSensitive}
            onChange={() => setFilterSensitive((prev) => !prev)}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label htmlFor="filter-sensitive" className="ml-3 text-gray-700">
            Filter Sensitive Content
          </label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={hideImages}
            onChange={() => setHideImages((prev) => !prev)}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label htmlFor="hide-images" className="ml-3 text-gray-700">
            Hide Images
          </label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={suppressAudio}
            onChange={() => setSuppressAudio((prev) => !prev)}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label htmlFor="suppress-audio" className="ml-3 text-gray-700">
            Suppress Audio
          </label>
        </div>
      </div>
    </div>
  );
};

const PaymentMethod = ({ card }) => {
  if (!card) return null;
  const { brand, last4, exp_year, exp_month } =
    card.payment_method.payment_methods[0];
  const formattedExpDate = `${exp_month
    .toString()
    .padStart(2, "0")}/${exp_year}`;

  return (
    <div className="py-2">
      <div className="flex-shrink-0">
        <CreditCard />
      </div>
      <div className="flex-1">
        <div className="text-sm font-medium text-gray-900">
          {`**** **** **** ${last4}`}
        </div>
        <div className="text-sm text-gray-600">Expiry: {formattedExpDate}</div>
      </div>
    </div>
  );
};

const PrivacySettings = () => {
  const [deleteHistory, setDeleteHistory] = useState(false);

  const handleSwitchChange = () => {
    setDeleteHistory((prevState) => !prevState);
  };

  return (
    <div className="py-2">
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          checked={deleteHistory}
          onChange={handleSwitchChange}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
        <label htmlFor="delete-history" className="ml-3 text-gray-700">
          Delete history after 60 days
        </label>
      </div>

      <div className="mt-4 text-gray-600">
        <a href="/faqs/" className="text-blue-500 hover:underline">
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

const ErrorBox = ({ message }) => {
  if (!message) return null;

  return (
    <div
      className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded-lg mb-4"
      role="alert"
    >
      <strong className="font-bold">Error: {` `}</strong>
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

const SuccessBox = ({ message }) => {
  if (!message) return null;

  return (
    <div
      className="bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded-lg mb-4"
      role="alert"
    >
      <strong className="font-bold">Success: {` `}</strong>
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

const SettingsTab = () => {
  const [activeTab, setActiveTab] = useState("account");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [payment, setPayment] = useState(null);
  const token = getToken();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const errorMessage = {
    40203: "Invalid old password!",
    40204: "Both old & new password is required!",
    40000: "Try a new complex password!",
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatePassword = async () => {
      setLoading(true);
      try {
        let header = getHeader();
        const response = await axios.post(
          `${API_URL}/api/v1/users/me/update-password/`,
          {
            old_password: oldPassword,
            new_password: password,
          },
          header
        );

        setSuccess("OK");
        setError(null);
      } catch (error) {
        setError(error.response.data.code);
        setSuccess(null);
      } finally {
        setLoading(false);
      }
    };
    updatePassword();
  };

  useEffect(() => {
    const getPayment = async () => {
      setLoading(true);
      let header = getHeader();
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/users/me/payment-method/`,
          header
        );
        setPayment(response.data);
      } catch (error) {
      } finally {
      }
    };
    getPayment();
  }, []);

  return (
    <div className="w-full">
      {/* Separator */}
      <hr className="border-t border-gray-300 my-4" />

      {/* Tabs Content */}
      <div className="p-4 border border-gray-200 rounded-lg">
        <div>
          <h2 className="text-xl font-bold mb-4">Change Password</h2>
          {error && errorMessage[error] && (
            <ErrorBox message={errorMessage[error]} />
          )}
          {success && <SuccessBox message={`Password changed successfully!`} />}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="password3"
              >
                Old Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password3"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder="Enter old password"
                required
                autoComplete="false"
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="password1"
              >
                New Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password1"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder="Enter new password"
                required
                autoComplete="false"
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="password2"
              >
                Confirm Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password2"
                autoComplete="false"
                value={password2}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder="Enter confirm password"
                required
              />
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={toggleShowPassword}
                className="mr-2"
              />
              <label htmlFor="showPassword" className="text-sm">
                Show Password
              </label>
            </div>

            <button
              type="submit"
              className="bg-info text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              disabled={loading}
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export function SettingsPage() {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Setting</h3>
      </div>
      <div className="flex flex-col">
        <SettingsTab />
      </div>
    </>
  );
}
