import { API_URL } from "@/config";
import axios from "axios";
import {
  Clock10Icon,
  EyeIcon,
  NewspaperIcon,
  SubscriptIcon,
} from "lucide-react";
import React, { useEffect, useState } from "react";

const TopStoriesWidget = () => {
  const [stories, setStories] = useState([]);

  function convertToMinutes(timeStr) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);

    // Convert total time to minutes (including seconds as a fraction of a minute)
    const totalMinutes = minutes + seconds / 60;

    // Round the result to the nearest integer
    return Math.round(totalMinutes);
  }

  useEffect(() => {
    // Make the API GET call using axios
    axios
      .get(`${API_URL}/api/v1/news/top-stories/`)
      .then((response) => {
        setStories(response.data.results); // Assuming the response data is in response.data
      })
      .catch((error) => {
        console.error("There was an error fetching the top stories:", error);
      });
  }, []);

  function formatNumberToK(number) {
    if (number >= 1000) {
      return (number / 1000).toFixed(2) + "K"; // Dividing by 1000 and adding 'K' with two decimal places
    }
    return number.toString(); // If less than 1000, return the number as is
  }

  return (
    <div className="rounded-lg w-full mb-3">
      <div className="mb-6 text-center w-full">
        <div className="inline-block px-6 p-1 bg-white  rounded-lg w-full">
          <h3 className="text-xl font-semibold text-gray-800 underline">
            Top Stories
          </h3>
        </div>
      </div>

      <div className="space-y-4 py-3">
        {stories &&
          stories.map((item, index) => (
            // border-gray-300
            <div
              key={index}
              className={` custom-border-2 p-3 transition duration-300 ease-in-out ${
                index === 0 ? "" : "border-l-4 "
              }`}
              style={{
                transition: "transform 0.2s ease-in-out", // Removed shadow transition
                borderWidth: "1px", // Ensure 1px border width
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.02)"; // Slight scale on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)"; // Reset scale
              }}
            >
              {index === 0 && item.resized_featured_image && (
                <div className="mb-2">
                  <img
                    src={item.resized_featured_image}
                    alt={item.title}
                    className="w-full h-auto rounded-md"
                  />
                </div>
              )}
              <div className="space-y-2">
                <h3 className="text-lg font-semibold text-gray-800 pointer underline-transition">
                  <a
                    href={`/posts/${item.slug}/`}
                    className="inline-block text-black hover:text-black" // Make sure the link stays black
                  >
                    {item.title}
                  </a>
                </h3>
                <ul className="flex flex-wrap justify-center space-x-6 text-sm text-gray-500 items-center">
                  {/* Provider Name */}
                  <li className="flex items-center space-x-2">
                    <NewspaperIcon className="w-4 h-4" />
                    <a
                      href={item.authorLink}
                      className="text-black hover:text-black"
                    >
                      {item.feeder.display_name}
                    </a>
                  </li>

                  {/* Time to Read */}
                  {/* <li className="flex items-center space-x-2">
                    <Clock10Icon className="w-4 h-4" />
                    <span>
                      {convertToMinutes(item.article_read_time) || 1} Min
                    </span>
                  </li> */}
                </ul>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const SubscriptionWidget = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">Subscribe</h3>
      <form>
        <input
          type="email"
          placeholder="Enter your email"
          className="w-full p-2 rounded-md border border-gray-300 mb-2"
        />
        <button className="w-full bg-blue-600 text-white p-2 rounded-md">
          Subscribe
        </button>
      </form>
    </div>
  );
};

const WeatherWidget = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">Weather</h3>
      <div className="flex flex-col items-center">
        <div className="text-2xl text-gray-800">25°C</div>
        <div className="text-sm text-gray-600">Sunny</div>
      </div>
    </div>
  );
};

const TopicsWidget = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="mb-4">
        <h3 className="text-xl font-semibold text-gray-800">Explore Topics</h3>
      </div>

      <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
        <ul className="space-y-3">
          <li className="flex justify-between items-center">
            <a
              href="archive-layout1.html"
              className="text-lg text-gray-700 hover:text-blue-600"
            >
              Culture
            </a>
            <span className="text-sm text-gray-500">(2)</span>
          </li>
          <li className="flex justify-between items-center">
            <a
              href="archive-layout1.html"
              className="text-lg text-gray-700 hover:text-blue-600"
            >
              Travel
            </a>
            <span className="text-sm text-gray-500">(1)</span>
          </li>
          <li className="flex justify-between items-center">
            <a
              href="archive-layout1.html"
              className="text-lg text-gray-700 hover:text-blue-600"
            >
              Business
            </a>
            <span className="text-sm text-gray-500">(5)</span>
          </li>
          <li className="flex justify-between items-center">
            <a
              href="archive-layout1.html"
              className="text-lg text-gray-700 hover:text-blue-600"
            >
              Trending
            </a>
            <span className="text-sm text-gray-500">(4)</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const SidebarWidget = () => {
  return (
    <div className="hidden md:inline-flex h-10 items-center justify-center rounded-md text-muted-error w-full  z-10 bg-white">
      <TopStoriesWidget />
      {/* <TopicsWidget /> */}
    </div>
  );
};

export default SidebarWidget;
