import React, { useState } from "react";
import { X, Check, WindIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

export function AccountOverlay(setIsVisible) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    setIsVisible(false);
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            zIndex: 10000,
            boxShadow: "0 -50px 35px rgba(0, 0, 0, 0.15)",
          }}
          className={`fixed bottom-0 left-0 w-full sm:h-2/3 h-[100dvh] bg-white transition-transform duration-300 rounded-t-lg ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          <button
            style={{ zIndex: 3 }}
            onClick={handleClose}
            className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-900"
            aria-label="Close"
          >
            <X size={24} />
          </button>
          <div className="p-6 text-center">
            <h2 className="text-3xl font-bold mb-2">Welcome to NewsNews</h2>
            <p className="mb-3 text-gray-700">
              Funding the next generation of newsrooms.
            </p>
          </div>

          {/* Content Container */}
          <div className="contianer overflow-y-auto h-[calc(100vh-380px)] p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-left">
              <div className="bg-white shadow-lg rounded-lg p-6 border">
                <div className="text-center mb-4">
                  <h2 className="text-2xl font-bold mb-2">Step One</h2>
                  <p className="text-gray-700">Create An Account</p>
                </div>
                <ul className="space-y-2 pl-4">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    Choose a username
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    Pick a password
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    Confirm via email
                  </li>
                </ul>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 border">
                <div className="text-center mb-4">
                  <h2 className="text-2xl font-bold mb-2">Step Two</h2>
                  <p className="text-gray-700">Pick a Subscription Tier</p>
                </div>
                <div className="space-y-4 pl-4">
                  <div className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    <div>
                      <h3 className="font-bold">Free</h3>
                      <p className="text-gray-700">
                        Get access to hundreds of non-profits and other free
                        newsrooms
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    <div>
                      <h3 className="font-bold">Basic</h3>
                      <p className="text-gray-700">
                        Get access to the above and a limited number of
                        for-profit publications
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    <div>
                      <h3 className="font-bold">Premium</h3>
                      <p className="text-gray-700">
                        Get access to the above and an expanded number of
                        for-profit publications
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 border">
                <div className="text-center mb-4">
                  <h2 className="text-2xl font-bold mb-2">Step Three</h2>
                  <p className="text-gray-700">Start reading the news</p>
                </div>
                <ul className="space-y-2 pl-4 mb-6">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    No Ads
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    No Tracking
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-green-500 mr-2" />
                    No AI*
                  </li>
                </ul>
                <div className="text-center mb-4 space-y-2 p-6">
                  <p className="text-gray-700">
                    We divvy up your subscription money and distribute it based
                    upon what you spend time reading.
                  </p>
                  <p className="text-gray-500 italic">
                    *And hopefully this is the last pop-up you ever see on this
                    site.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-6 right-6">
            <button
              onClick={() => {
                navigate("/account/");
              }}
              className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-blue-600"
            >
              Get Started
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export function MobileOverlay({ setIsVisible }) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    setIsVisible(false);
    window.location.href = "/";
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            zIndex: 10000,
            boxShadow: "0 -50px 35px rgba(0, 0, 0, 0.15)",
          }}
          className={`fixed bottom-0 left-0 w-full h-[100dvh] bg-white transition-transform duration-300 rounded-t-lg ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          <button
            style={{ zIndex: 3 }}
            onClick={handleClose}
            className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-900"
            aria-label="Close"
          >
            <X size={24} />
          </button>
          <div className="p-6 text-center">
            <h2 className="text-2xl font-bold mb-2">Welcome to NewsNews</h2>
            <p className=" text-gray-700">
              Supporting The Next Generation of Newsrooms.
            </p>
          </div>

          <div className="p-4">
            <h2 className="text-lg mb-2">Getting Started is Easy</h2>
            <div className="space-y-4">
              <div>
                <h2 className="text-2xl font-semibold mb-3">
                  Step 1: Create an Account
                </h2>
                <p className="text-gray-700">Just an email and a password</p>
              </div>

              <div>
                <h2 className="text-2xl font-semibold mb-3">
                  Step 2: Select a Plan
                </h2>
                <p className="text-gray-700">
                  Three to choose from and, yes, there&apos;s a free tier.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-semibold mb-3">
                  Step 3: Build a Custom Feed
                </h2>
                <p className="text-gray-700">
                  Select the publications you want, then start reading (and
                  funding) independent news orgs.
                </p>
              </div>
            </div>
            <h2 className="text-lg mb-2 py-2 text-center">
              No Ads. No Tracking. No AI.
            </h2>
            <div className="flex m-4 items-center justify-center">
              <button
                onClick={() => {
                  navigate("/account/");
                }}
                className="bg-[rgb(160,39,37)] text-white py-2 px-8 rounded-lg hover:bg-[rgb(140,29,27)]"
              >
                Get Started
              </button>
            </div>
            <p className="text-gray-700 mb-4">
              We&apos;ll distribute your subscription money based on what you
              read, so your money is going to fund more local and independent
              journalism.
              <span className="text-blue-500 font-semibold cursor-pointer hover:underline">
                <a href="/faqs">Learn More</a>
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
}
export function DesktopOverlay({ setIsVisible }) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    setIsVisible(false);
    window.location.href = "/";
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="absolute inset-0" style={{ backgroundColor: "rgba(78, 79, 80, 1)", opacity: 0.7 }}></div>
          <div className="relative bg-white w-[500px] max-w-full p-6 rounded-lg shadow-lg">
            <button
              className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-900"
              onClick={handleClose}
              aria-label="Close"
            >
              <X size={24} />
            </button>
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-2">Welcome to NewsNews</h2>
              <p className="text-gray-700">
                Supporting The Next Generation of Newsrooms.
              </p>
            </div>
            <div className="mt-4">
              <h2 className="text-lg mb-2">Getting Started is Easy</h2>
              <div className="space-y-4">
                <div>
                  <h2 className="text-xl font-semibold mb-3">
                    Step 1: Create an Account
                  </h2>
                  <p className="text-gray-700">Just an email and a password.</p>
                </div>
                <div>
                  <h2 className="text-xl font-semibold mb-3">
                    Step 2: Select a Plan
                  </h2>
                  <p className="text-gray-700">
                    Three to choose from, including a free tier.
                  </p>
                </div>
                <div>
                  <h2 className="text-xl font-semibold mb-3">
                    Step 3: Build a Custom Feed
                  </h2>
                  <p className="text-gray-700">
                    Select publications, start reading, and support independent
                    news.
                  </p>
                </div>
              </div>
              <h2 className="text-lg my-4 text-center">
                No Ads. No Tracking. No AI.
              </h2>
              <div className="flex items-center justify-center">
                <button
                  onClick={() => navigate("/account/")}
                  className="bg-red-600 text-white py-2 px-8 rounded-lg hover:bg-red-700"
                >
                  Get Started
                </button>
              </div>
              <p className="text-gray-700 mt-4 text-center">
                Your subscription funds local and independent journalism.
                <span className="text-blue-500 font-semibold cursor-pointer hover:underline">
                  <a href="/faqs">Learn More</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
