import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import { MessageSquareDiff } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import React, { useState, useEffect } from "react";

import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icon";
import { API_URL } from "@/config";
import axios from "axios";

import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { useNavigate } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";

import { UserRoundPlus, KeySquare } from "lucide-react";
import { getRandomQuote } from "./quotes";
import { setToken as setAccessToken } from "../utils";

export function AlertDestructiveMessage({ msg }) {
  if (!msg) return <></>;
  return <Alert variant="destructive">{msg}</Alert>;
}

export function Verify({ className, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [subscription, setSubscription] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = localStorage.getItem("user") || searchParams.get("alias");

  useEffect(() => {
    if (!user) {
      navigate("/login/");
    } else {
      const code = searchParams.get("code");
      if (code) {
        setToken(code);
        verifyCode(code);
      } else {
        checkUserActivation();
      }
    }
  }, [user, searchParams, navigate]);

  async function verifyCode(token) {
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post(`${API_URL}/api/v1/users/verify/`, {
        user,
        token,
      });
      if (response.data.access) {
        const subscription = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${response.data.access}`,
            },
          }
        );
        setSubscription(subscription)
      }
      localStorage.removeItem("user");
      setAccessToken(response.data.access);
      const path = localStorage.getItem("ldirect");
      if (path && subscription && subscription.has_access) {
        localStorage.removeItem("ldirect");
        navigate(path);
      } else {
        navigate("/pricing/");
      }
    } catch (error) {
      setError(error.response.data.msg);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmit(event) {
    event.preventDefault();
    await verifyCode(token);
  }

  useEffect(() => {
    if (!user) return;

    const intervalId = setInterval(() => {
      checkUserActivation();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [user]);

  async function checkUserActivation() {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/users/check-activation/`,
        {
          user,
        }
      );
      if (response.data.verified) {
        const path = localStorage.getItem("ldirect");
        navigate(path || "/");
      }
    } catch (error) {
      console.log("User not activated");
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <AlertDestructiveMessage msg={error} />
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="code">
              Code
            </Label>
            <Input
              id="code"
              placeholder="123456"
              type="text"
              autoCorrect="off"
              disabled={isLoading}
              required="required"
              onChange={(e) => setToken(e.target.value)}
            />
          </div>
          <Button className="bg-info" disabled={isLoading}>
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Verify
          </Button>
          <div className="grid grid-cols-2 gap-6">
            <button
              onClick={() => navigate("/account/")}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2"
            >
              <UserRoundPlus className="mr-2 h-4 w-4" />
              Sign Up
            </button>
            <button
              onClick={() => navigate("/login/")}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2"
            >
              <KeySquare className="mr-2 h-4 w-4" />
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export const metadata = {
  title: "Authentication",
  description: "Authentication forms built using the components.",
};

export function VerifyPage() {
  const navigate = useNavigate();
  const quote = getRandomQuote()
  return (
    <>
      <div className="md:hidden">
        <img
          src="https://dev.newsne.ws/logo_nemesis.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="block dark:hidden p-4"
          onClick={() => navigate("/")}
        />
        <img
          src="https://dev.newsne.ws/logo_nemesis.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="hidden dark:block"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="container relative h-[800px] flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-info" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <a style={{ hover: "none" }} href="/">
              Newsne.ws
            </a>
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;{quote.quote}&rdquo;
              </p>
              <footer className="text-sm">{quote.artist}</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Login to your account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your verification code to verify!
              </p>
            </div>
            <Verify />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
