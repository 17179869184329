import { useEffect, useState } from "react";
import { getHeader, getToken } from "./utils";
import axios from "axios";
import { API_URL } from "@/config";
import {
  FeedsAccordion,
  PredefinedDatesAccordion,
  StateAcordion,
  SubjectsAccordion,
} from "./filter";

export const TestFilter = ({ setFilterConfig = null, firehouse = null }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [priceRange, setPriceRange] = useState([10, 100]);
  const [purchased, setPurchased] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFeeds, setSelectedFeeds] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [subscribedFeeds, setSubscribedFeeds] = useState([]);
  const [filteredSubscribedFeeds, setFilteredSubscribedFeeds] = useState([]);
  const [topStories, setTopStories] = useState(false);
  const [allFeeds, setAllFeeds] = useState(false);
  const [filterControll, setFilterControll] = useState("date");
  const [data, setData] = useState(null);
  const token = getToken();
  function storeData(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  function getData(key) {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }
  function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // Create an object containing the value and the expiration time
    const item = {
      value: value, // The JSON object you're storing
      expiry: now.getTime() + ttl, // The expiration time in milliseconds
    };

    // Convert the object to a string before saving in localStorage
    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    // Parse the stored string back into an object
    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the current time with the expiry time
    if (now.getTime() > item.expiry) {
      // If the item has expired, remove it from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    // Return the stored JSON object
    return item.value;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if data is already stored in sessionStorage
        const cachedData = sessionStorage.getItem("apiData");

        if (cachedData) {
          setData(JSON.parse(cachedData));
        } else {
          // Fetch data using Axios
          const response = await axios.get(
            `${API_URL}/api/v1/news/providers-state/`
          );
          setData(response.data);
          sessionStorage.setItem("apiData", JSON.stringify(response.data));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAllFeeds = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/news/providers/`);
        setAllFeeds(response.data);
        storeData("all_feeds", response.data);
        setSubscribedFeeds(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchSubscription = async () => {
      try {
        let header = getHeader();
        const subscriptionResponse = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          header
        );
        setSubscribedFeeds(subscriptionResponse.data.feeds);
        storeData("feeds", subscriptionResponse.data.feeds);
      } catch (error) {
        console.error("Error during subscription request:", error);
      } finally {
      }
    };
    const fetchTags = async () => {
      try {
        const tags = await axios.get(`${API_URL}/api/v1/news/tags/`);
        setFilteredCategories(tags.data);
        setWithExpiry("categories", tags.data, 7 * 24 * 60 * 60 * 1000);
      } catch (error) {
        console.error("Error during tags request:", error);
      } finally {
      }
    };
    let ffeeds = getData("feeds");
    let all_feeds = getData("all_feeds");
    let categories = getWithExpiry("categories");

    if (!token) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    } else if (!firehouse) {
      if (ffeeds) {
        setSubscribedFeeds(ffeeds);
      } else {
        fetchSubscription();
      }
    } else if (firehouse) {
      if (!all_feeds) {
        fetchAllFeeds();
      } else {
        setSubscribedFeeds(all_feeds);
      }
    }

    if (categories == null || categories == undefined) {
      fetchTags();
    } else {
      setFilteredCategories(categories);
    }
    clearFilters();
  }, [firehouse]);

  const predefinedRanges = [
    {
      label: "Any",
      start: "",
      end: "",
    },
    {
      label: "Today",
      start: () => new Date().toISOString().split("T")[0],
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "Yesterday",
      start: () => getDateDaysAgo(2),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "1 Week Ago",
      start: () => getDateDaysAgo(7),
      end: () => new Date().toISOString().split("T")[0],
    },
    {
      label: "Custom Date Range",
    },
  ];

  const addCategory = (category) => {
    let canAdd = true;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (category.alias === selectedCategories[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedCategories([...selectedCategories, category]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const removeCategory = (category) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };
  const OldaddStates = (state) => {
    let canAdd = true;
    for (let i = 0; i < selectedStates.length; i++) {
      if (state[0] === selectedStates[i][0]) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedStates([...selectedStates, state]);
    }
    setSearchTerm(""); // Clear the search term
  };
  const addOrRemoveState = (state) => {
    const exists = selectedStates.some((s) => s[0] === state[0]);

    if (exists) {
      // Remove the state if it already exists
      setSelectedStates(selectedStates.filter((s) => s[0] !== state[0]));
    } else {
      // Add the state if it doesn't exist
      setSelectedStates([...selectedStates, state]);
    }

    setSearchTerm(""); // Clear the search term
  };

  const addFeeds = (feed) => {
    let canAdd = true;
    for (let i = 0; i < selectedFeeds.length; i++) {
      if (feed.alias === selectedFeeds[i].alias) {
        canAdd = false;
        break; // Stop the loop if a match is found
      }
    }
    if (canAdd) {
      setSelectedFeeds([...selectedFeeds, feed]);
    }
    setSearchTerm(""); // Clear the search term
  };

  const addOrRemoveFeed = (feed) => {
    const exists = selectedFeeds.some((f) => f.alias === feed.alias);

    if (exists) {
      // Remove the feed if it already exists
      setSelectedFeeds(selectedFeeds.filter((f) => f.alias !== feed.alias));
    } else {
      // Add the feed if it doesn't exist
      setSelectedFeeds([...selectedFeeds, feed]);
    }

    setSearchTerm(""); // Clear the search term
  };

  const removeFeeds = (feed) => {
    setSelectedFeeds(selectedFeeds.filter((item) => item.alias !== feed.alias));
  };

  const handlePredefinedRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    if (start == undefined && end == undefined) {
      setSelectedPredefinedRange("Custom date");
    } else if (start == "" && end == "") {
      setSelectedPredefinedRange("Any");
    } else {
      setSelectedPredefinedRange(
        predefinedRanges.find(
          (range) => range.start === start && range.end === end
        ).label
      );
    }
  };

  const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split("T")[0];
  };

  const toggleCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  function filterMappingsByState(states, mappings) {
    if (!Array.isArray(states) || !Array.isArray(mappings)) {
      console.error("Invalid input: states and mappings must be arrays.");
      return [];
    }

    const stateIds = new Set(
      states
        .map((state) => (Array.isArray(state) ? state[0] : null))
        .filter((id) => id !== null)
    );

    return mappings.filter((mapping) => {
      if (typeof mapping !== "object" || mapping === null) return false;
      return stateIds.has(mapping.state);
    });
  }

  function filterList(objects, mappings) {

    return mappings
      .map(({ alias, state }) => {
        // Destructure alias and state from object
        const matchedObject = objects.find((obj) => obj.alias === alias);
        return matchedObject ? { ...matchedObject, state } : null; // Include matched object and state
      })
      .filter((item) => item !== null); // Filter out any null values
  }

  useEffect(() => {
    if (selectedStates.length == 0) {
      setFilteredSubscribedFeeds(subscribedFeeds);
    } else {
      const filteredMapState = filterMappingsByState(selectedStates, data);
      const filteredMap = filterList(subscribedFeeds, filteredMapState);
      setFilteredSubscribedFeeds(filteredMap);
    }
  }, [selectedStates]);

  const applyFilters = () => {
    setFilterConfig({
      selectedFeeds: selectedFeeds,
      selectedCategories: selectedCategories,
      purchased: purchased,
      selectedStates: selectedStates,
      date: {
        startDate,
        endDate,
      },
      topStories: topStories,
    });
  };

  useEffect(() => {
    setFilterConfig({
      selectedFeeds: selectedFeeds,
      selectedCategories: selectedCategories,
      purchased: purchased,
      selectedStates: selectedStates,
      date: {
        startDate,
        endDate,
      },
      topStories: topStories,
    });
  }, [
    selectedFeeds,
    selectedCategories,
    purchased,
    selectedStates,
    startDate,
    endDate,
    topStories,
  ]);

  const removeStates = (state) => {
    setSelectedStates(selectedStates.filter((item) => item[0] !== state[0]));
  };

  const clearFilters = () => {
    setFilterConfig({});
    setSelectedCategories([]);
    setSelectedFeeds([]);
    setSelectedStates([]);
    setStartDate(null);
    setEndDate(null);
    setTopStories(false);
    setPurchased(false);
    setSelectedPredefinedRange("Any");
  };
  return (
    <div className="w-full max-w-4xl mx-auto ">
      <div className="flex flex-wrap items-center  border justify-between gap-4 bg-white border-gray p-2 rounded-lg">
        <div className="border-l-4 border-blue-500 pl-4">Filter</div>

        <div className="space-y-1 w-full">
          {token && (
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                value="false"
                onChange={() => setPurchased(!purchased)}
                checked={purchased}
                className="mr-2"
              />
              Purchased
            </label>
          )}

          {selectedStates && selectedStates.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Selected State</h3>
              <div className="flex flex-wrap gap-2">
                {selectedStates.map((state) => (
                  <span
                    key={state[0]}
                    className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                  >
                    {state[1]}
                    <button
                      className="ml-2 text-blue-600 hover:text-blue-800"
                      onClick={() => removeStates(state)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>
          )}

          {selectedFeeds && selectedFeeds.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Selected Feeds</h3>
              <div className="flex flex-wrap gap-2">
                {selectedFeeds.map((feed) => (
                  <span
                    key={feed.alias}
                    className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                  >
                    {feed.display_name || feed.name}
                    <button
                      className="ml-2 text-blue-600 hover:text-blue-800"
                      onClick={() => removeFeeds(feed)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>
          )}

          {selectedCategories && selectedCategories.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">Selected Tags</h3>
              <div className="flex flex-wrap gap-2">
                {selectedCategories
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((category) => (
                    <span
                      key={category.alias}
                      className="flex items-center bg-blue-100 text-blue-600 px-2 py-1 rounded-full"
                    >
                      {category.title.toUpperCase()}
                      <button
                        className="ml-2 text-blue-600 hover:text-blue-800"
                        onClick={() => removeCategory(category)}
                      >
                        &times;
                      </button>
                    </span>
                  ))}
              </div>
            </div>
          )}
          <PredefinedDatesAccordion
            selectedDateRange={selectedPredefinedRange}
            predefinedDateRanges={predefinedRanges}
            setDateRange={handlePredefinedRange}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            filterControll={filterControll}
            setFilterControll={setFilterControll}
          />
          {/* <div className="max-h-60 overflow-y-auto"> */}
          <div>
            <StateAcordion
              selectedStates={selectedStates}
              addStates={addOrRemoveState}
              removeState={removeStates}
              filterControll={filterControll}
              setFilterControll={setFilterControll}
            />
          </div>

          {/* <div className="max-h-60 overflow-y-auto"> */}
          <div>
            <FeedsAccordion
              selectedFeeds={selectedFeeds}
              subscribedFeeds={filteredSubscribedFeeds}
              addFeeds={addOrRemoveFeed}
              removeFeeds={removeFeeds}
              filterControll={filterControll}
              setFilterControll={setFilterControll}
            />
          </div>

          {/* <div className="max-h-60 overflow-y-auto"> */}
          <div>
            <SubjectsAccordion
              selectedCategories={selectedCategories}
              filteredCategories={filteredCategories}
              addCategory={addCategory}
              removeCategory={removeCategory}
              filterControll={filterControll}
              setFilterControll={setFilterControll}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
