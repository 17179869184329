import { v4 as uuidv4 } from 'uuid';

const TOKEN_KEY = "token";
const EXPIRATION_KEY = "token_expiration";
const EXPIRATION_DAYS = 6;

export const generateUniqueId = () => {
  return uuidv4(); 
};

// Helper function to calculate expiration date
const calculateExpirationDate = (days) => {
  const now = new Date();
  now.setDate(now.getDate() + days);
  return now.getTime(); // Returns timestamp in milliseconds
};

// Set token with expiration
export const setToken = (value) => {
  try {
    const expirationDate = calculateExpirationDate(EXPIRATION_DAYS);
    localStorage.setItem(TOKEN_KEY, value);
    localStorage.setItem(EXPIRATION_KEY, expirationDate.toString());
  } catch (error) {
    console.error("Error setting token:", error);
  }
};

// Get token and check expiration
export const getToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    const expiration = localStorage.getItem(EXPIRATION_KEY);

    if (!token || !expiration) {
      return null; // No token or expiration date found
    }

    const now = Date.now();
    if (now > parseInt(expiration, 10)) {
      // Token has expired
      removeAccessToken(); // Remove token and expiration
      return null;
    }

    return token; // Token is valid
  } catch (error) {
    console.error("Error getting token:", error);
    return null;
  }
};

// Remove token and expiration date
export const removeAccessToken = () => {
  try {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(EXPIRATION_KEY);
  } catch (error) {
    console.error("Error removing token:", error);
  }
};

// Get headers with token if valid
export const getHeader = () => {
  const token = getToken();
  let header = {};
  if (token) {
    header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return header;
};

export const toLocalTime = (date) => {
  const utcDate = new Date(date);
  const localDate = utcDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return localDate;
};


export function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // Create an object containing the value and the expiration time
  const item = {
    value: value, // The JSON object you're storing
    expiry: now.getTime() + ttl, // The expiration time in milliseconds
  };

  // Convert the object to a string before saving in localStorage
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  // Parse the stored string back into an object
  const item = JSON.parse(itemStr);
  const now = new Date();

  // Compare the current time with the expiry time
  if (now.getTime() > item.expiry) {
    // If the item has expired, remove it from storage and return null
    localStorage.removeItem(key);
    return null;
  }
  // Return the stored JSON object
  return item.value;
}