import React from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { getToken } from './routes/utils';

export function PrivateRoute({ element, ...rest }) {
  const token = getToken; // Replace with your auth logic
  const navigate = useNavigate()
  if(!token){
    navigate('/login/')
  }

  return (
    <>{element}</>
  );
}


