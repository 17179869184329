import { Badge } from "@/components/ui/badge";

import { SiteHeader } from "@/routes/site-header";
import { Separator } from "@radix-ui/react-select";
import { ArrowDown } from "lucide-react";
import { Link } from 'react-router-dom';

import {Footer} from "@/routes/footer"

import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "@/config";

function NewsItem(items) {
  return (
    <>
      <div className="xl:container mx-auto px-3 sm:px-4 xl:px-2">
        <div className="flex flex-row flex-wrap">
          <div className="flex-shrink max-w-full w-full lg:w-2/3 overflow-hidden">
            <div className="w-full py-3">
              <h2 className="text-gray-800 text-2xl font-bold">
                <span className="inline-block h-5 border-l-3 border-red-600 mr-2"></span>
                The Daily Sun
                <hr></hr>
                {/* <Separator></Separator> */}
              </h2>
            </div>

            <div className="flex flex-row flex-wrap -mx-3">
              <div className="flex-shrink max-w-full w-full sm:w-1/3 px-3 pb-3 pt-3 sm:pt-0 border-b-2 sm:border-b-0 border-dotted border-gray-100">
                <div className="flex flex-row sm:block hover-img">
                  <a href="">
                    <img
                      className="max-w-full w-full mx-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img2.jpg"
                      alt="alt title"
                    />
                  </a>
                  <div className="py-0 sm:py-3 pl-3 sm:pl-0">
                    <h3 className="text-lg font-bold leading-tight mb-2">
                      <a href="#">
                        5 Tips to Save Money Booking Your Next Hotel Room
                      </a>
                    </h3>
                    <p className="hidden md:block text-gray-600 leading-tight mb-1">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content.
                    </p>
                    <span className="text-gray-500" href="#">
                      {/* <span className="inline-block h-3 border-l-2 border-red-600 mr-2"></span> */}
                      <div className="flex items-center gap-2">
                        <a href="/">
                          <Badge key="work" variant="default">
                            work
                          </Badge>
                        </a>
                        <a href="/">
                          <Badge key="work" variant="default">
                            Europe
                          </Badge>
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex-shrink max-w-full w-full sm:w-1/3 px-3 pb-3 pt-3 sm:pt-0 border-b-2 sm:border-b-0 border-dotted border-gray-100">
                <div className="flex flex-row sm:block hover-img">
                  <a href="">
                    <img
                      className="max-w-full w-full mx-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img3.jpg"
                      alt="alt title"
                    />
                  </a>
                  <div className="py-0 sm:py-3 pl-3 sm:pl-0">
                    <h3 className="text-lg font-bold leading-tight mb-2">
                      <a href="#">
                        5 Tips to Save Money Booking Your Next Hotel Room
                      </a>
                    </h3>
                    <p className="hidden md:block text-gray-600 leading-tight mb-1">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content.
                    </p>
                    <span className="text-gray-500" href="#">
                      {/* <span className="inline-block h-3 border-l-2 border-red-600 mr-2"></span> */}
                      <div className="flex items-center gap-2">
                        <a href="/">
                          <Badge key="work" variant="default">
                            work
                          </Badge>
                        </a>
                        <a href="/">
                          <Badge key="work" variant="default">
                            Europe
                          </Badge>
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-shrink max-w-full w-full sm:w-1/3 px-3 pb-3 pt-3 sm:pt-0 border-b-2 sm:border-b-0 border-dotted border-gray-100">
                <div className="flex flex-row sm:block hover-img">
                  <a href="">
                    <img
                      className="max-w-full w-full mx-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img4.jpg"
                      alt="alt title"
                    />
                  </a>
                  <div className="py-0 sm:py-3 pl-3 sm:pl-0">
                    <h3 className="text-lg font-bold leading-tight mb-2">
                      <a href="#">
                        5 Tips to Save Money Booking Your Next Hotel Room
                      </a>
                    </h3>
                    <p className="hidden md:block text-gray-600 leading-tight mb-1">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content.
                    </p>
                    <span className="text-gray-500" href="#">
                      {/* <span className="inline-block h-3 border-l-2 border-red-600 mr-2"></span> */}
                      <div className="flex items-center gap-2">
                        <a href="/">
                          <Badge key="work" variant="default">
                            work
                          </Badge>
                        </a>
                        <a href="/">
                          <Badge key="work" variant="default">
                            Europe
                          </Badge>
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-shrink max-w-full w-full sm:w-1/3 px-3 pb-3 pt-3 sm:pt-0 border-b-2 sm:border-b-0 border-dotted border-gray-100">
                <div className="flex flex-row sm:block hover-img">
                  <a href="">
                    <img
                      className="max-w-full w-full mx-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img5.jpg"
                      alt="alt title"
                    />
                  </a>
                  <div className="py-0 sm:py-3 pl-3 sm:pl-0">
                    <h3 className="text-lg font-bold leading-tight mb-2">
                      <a href="#">
                        5 Tips to Save Money Booking Your Next Hotel Room
                      </a>
                    </h3>
                    <p className="hidden md:block text-gray-600 leading-tight mb-1">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content.
                    </p>
                    <span className="text-gray-500" href="#">
                      {/* <span className="inline-block h-3 border-l-2 border-red-600 mr-2"></span> */}
                      <div className="flex items-center gap-2">
                        <a href="/">
                          <Badge key="work" variant="default">
                            work
                          </Badge>
                        </a>
                        <a href="/">
                          <Badge key="work" variant="default">
                            Europe
                          </Badge>
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-shrink max-w-full w-full sm:w-1/3 px-3 pb-3 pt-3 sm:pt-0 border-b-2 sm:border-b-0 border-dotted border-gray-100">
                <div className="flex flex-row sm:block hover-img">
                  <a href="">
                    <img
                      className="max-w-full w-full mx-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img6.jpg"
                      alt="alt title"
                    />
                  </a>
                  <div className="py-0 sm:py-3 pl-3 sm:pl-0">
                    <h3 className="text-lg font-bold leading-tight mb-2">
                      <a href="#">
                        5 Tips to Save Money Booking Your Next Hotel Room
                      </a>
                    </h3>
                    <p className="hidden md:block text-gray-600 leading-tight mb-1">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content.
                    </p>
                    <span className="text-gray-500" href="#">
                      {/* <span className="inline-block h-3 border-l-2 border-red-600 mr-2"></span> */}
                      <div className="flex items-center gap-2">
                        <a href="/">
                          <Badge key="work" variant="default">
                            work
                          </Badge>
                        </a>
                        <a href="/">
                          <Badge key="work" variant="default">
                            Europe
                          </Badge>
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-shrink max-w-full w-full sm:w-1/3 px-3 pb-3 pt-3 sm:pt-0 border-b-2 sm:border-b-0 border-dotted border-gray-100">
                <div className="flex flex-row sm:block hover-img">
                  <a href="">
                    <img
                      className="max-w-full w-full mx-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img7.jpg"
                      alt="alt title"
                    />
                  </a>
                  <div className="py-0 sm:py-3 pl-3 sm:pl-0">
                    <h3 className="text-lg font-bold leading-tight mb-2">
                      <a href="#">
                        5 Tips to Save Money Booking Your Next Hotel Room
                      </a>
                    </h3>
                    <p className="hidden md:block text-gray-600 leading-tight mb-1">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content.
                    </p>
                    <span className="text-gray-500" href="#">
                      {/* <span className="inline-block h-3 border-l-2 border-red-600 mr-2"></span> */}
                      <div className="flex items-center gap-2">
                        <a href="/">
                          <Badge key="work" variant="default">
                            work
                          </Badge>
                        </a>
                        <a href="/">
                          <Badge key="work" variant="default">
                            Europe
                          </Badge>
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-shrink max-w-full w-full lg:w-1/3 lg:pl-8 lg:pt-14 lg:pb-8 order-first lg:order-last"
            style={{ position: "relative" }}
          >
            <div className="w-full bg-white">
              <div className="mb-6">
                <div className="p-4 bg-gray-100">
                  <h2 className="text-lg font-bold">Most Popular</h2>
                </div>
                <ul className="post-number">
                  <li className="border-b border-gray-100 ">
                    <a
                      className="text-lg font-bold px-6 py-3 flex flex-row items-center"
                      href="#"
                    >
                      Why the world would end without political polls
                    </a>
                  </li>
                  <li className="border-b border-gray-100 ">
                    <a
                      className="text-lg font-bold px-6 py-3 flex flex-row items-center"
                      href="#"
                    >
                      Meet The Man Who Designed The Ducati Monster
                    </a>
                  </li>
                  <li className="border-b border-gray-100 ">
                    <a
                      className="text-lg font-bold px-6 py-3 flex flex-row items-center"
                      href="#"
                    >
                      2020 Audi R8 Spyder spy shots release
                    </a>
                  </li>
                  <li className="border-b border-gray-100 ">
                    <a
                      className="text-lg font-bold px-6 py-3 flex flex-row items-center"
                      href="#"
                    >
                      Lamborghini makes Huracán GT3 racer faster for 2019
                    </a>
                  </li>
                  <li className="border-b border-gray-100 ">
                    <a
                      className="text-lg font-bold px-6 py-3 flex flex-row items-center"
                      href="#"
                    >
                      ZF plans $14 billion autonomous vehicle push, concept van
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="text-sm py-6" style="position: static; left: auto; width: 389px;">
              <div className="w-full text-center">
                <a className="uppercase" href="#">Advertisement</a>
                <a href="#">
                  <img className="mx-auto" src="src/img/ads/250.jpg" alt="advertisement area" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Separator></Separator>
    </>
  );
}

function TopNews() {
  return (
    <div className="bg-white py-6">
      <div className="xl:container mx-auto px-3 sm:px-4 xl:px-2">
        <div className="flex flex-row flex-wrap">
          <div className="flex-shrink max-w-full w-full lg:w-1/2 pb-1 lg:pb-0 lg:pr-1">
            <div className="relative hover-img max-h-98 overflow-hidden">
              <a href="#">
                <img
                  className="max-w-full w-full mx-auto h-auto"
                  src="https://tailnews.tailwindtemplate.net/src/img/dummy/img8.jpg"
                  alt="Image description"
                />
              </a>
              <div className="absolute px-5 pt-8 pb-5 bottom-0 w-full bg-gradient-cover">
                <a href="#">
                  <h2 className="text-3xl font-bold capitalize text-white mb-3">
                    Amazon Shoppers Are Ditching Designer Belts for This
                    Best-Selling
                  </h2>
                </a>
                <p className="text-gray-100 hidden sm:inline-block">
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This very helpfull for generate
                  default content..
                </p>
                <div className="pt-2">
                  <div className="text-gray-100">
                    <div className="inline-block h-3 border-l-2 border-red-600 mr-2"></div>
                    Europe
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-shrink max-w-full w-full lg:w-1/2">
            <div className="box-one flex flex-row flex-wrap">
              <article className="flex-shrink max-w-full w-full sm:w-1/2">
                <div className="relative hover-img max-h-48 overflow-hidden">
                  <a href="#">
                    <img
                      className="max-w-full w-full mx-auto h-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img9.jpg"
                      alt="Image description"
                    />
                  </a>
                  <div className="absolute px-4 pt-7 pb-4 bottom-0 w-full bg-gradient-cover">
                    <a href="#">
                      <h2 className="text-lg font-bold capitalize leading-tight text-white mb-1">
                        News magazines are becoming obsolete, replaced by
                        gadgets
                      </h2>
                    </a>
                    <div className="pt-1">
                      <div className="text-gray-100">
                        <div className="inline-block h-3 border-l-2 border-red-600 mr-2"></div>
                        Techno
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article className="flex-shrink max-w-full w-full sm:w-1/2">
                <div className="relative hover-img max-h-48 overflow-hidden">
                  <a href="#">
                    <img
                      className="max-w-full w-full mx-auto h-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img10.jpg"
                      alt="Image description"
                    />
                  </a>
                  <div className="absolute px-4 pt-7 pb-4 bottom-0 w-full bg-gradient-cover">
                    <a href="#">
                      <h2 className="text-lg font-bold capitalize leading-tight text-white mb-1">
                        Minimalist designs are starting to be popular with the
                        next generation
                      </h2>
                    </a>
                    <div className="pt-1">
                      <div className="text-gray-100">
                        <div className="inline-block h-3 border-l-2 border-red-600 mr-2"></div>
                        Architecture
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article className="flex-shrink max-w-full w-full sm:w-1/2">
                <div className="relative hover-img max-h-48 overflow-hidden">
                  <a href="#">
                    <img
                      className="max-w-full w-full mx-auto h-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img11.jpg"
                      alt="Image description"
                    />
                  </a>
                  <div className="absolute px-4 pt-7 pb-4 bottom-0 w-full bg-gradient-cover">
                    <a href="#">
                      <h2 className="text-lg font-bold capitalize leading-tight text-white mb-1">
                        Tips for decorating the interior of the living room
                      </h2>
                    </a>
                    <div className="pt-1">
                      <div className="text-gray-100">
                        <div className="inline-block h-3 border-l-2 border-red-600 mr-2"></div>
                        Interior
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article className="flex-shrink max-w-full w-full sm:w-1/2">
                <div className="relative hover-img max-h-48 overflow-hidden">
                  <a href="#">
                    <img
                      className="max-w-full w-full mx-auto h-auto"
                      src="https://tailnews.tailwindtemplate.net/src/img/dummy/img1.jpg"
                      alt="Image description"
                    />
                  </a>
                  <div className="absolute px-4 pt-7 pb-4 bottom-0 w-full bg-gradient-cover">
                    <a href="#">
                      <h2 className="text-lg font-bold capitalize leading-tight text-white mb-1">
                        Online taxi users are increasing drastically ahead of
                        the new year
                      </h2>
                    </a>
                    <div className="pt-1">
                      <div className="text-gray-100">
                        <div className="inline-block h-3 border-l-2 border-red-600 mr-2"></div>
                        Lifestyle
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


function LoadMode() {
  return (
    <div className="blog-pager" id="blog-pager">
      <div className="list-inline">
        <a
          className="blog-pager-older-link list-inline-item"
          href="#"
          title="More posts"
        >
          <div className="fbt-bp-message text-uppercase font-weight-bold">
            More posts
          </div>
          <ArrowDown className="justify-center inline-flex"></ArrowDown>
        </a>
      </div>
    </div>
  );
}

function SecondNews() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Function to fetch data
  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/news/todays/?page=${page}`
      );
      if(page === 1){
        setData(response.data.results);
      }else{
        setData((prevData) => [...prevData, ...response.data.results]);
      }
      setHasMore(response.data.next || false)
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Initial data fetch
  useEffect(() => {
    fetchData(page);
  }, [page]);

  const handleLoadMore = (event) => {
    event.preventDefault();
    if(hasMore){
      setPage((prevPage) => prevPage + 1);
    }
   
  };

  return (
    <div className="fbt-main-wrapper col-xl-12">
        <div className="w-full py-3">
              <h2 className="text-gray-800 text-2xl font-bold">
                <span className="inline-block h-5 border-l-3 border-red-600 mr-2"></span>
                Active news
                <hr></hr>
                {/* <Separator></Separator> */}
              </h2>
            </div>
        
      <div id="main-wrapper">
        <div className="main-section" id="main_content">
          {/* <div className="blog-posts fbt-index-post-wrap card-columns">
            
          </div> */}
          <div className="blog-posts fbt-index-post-wrap card-columns">
          {data &&
              data.map((item, index) => (
                <div
                  key={item.alias}
                  className={`blog-post card radius-10 fbt-index-post ${index > 15 ? '' : 'hentry'}`}
                  // className="blog-post fbt-index-post card radius-10"
                >
                  <div className="fbt-post-thumbnail">
                    <a href={`/posts/${item.slug}/`}>
                      <img
                        alt=""
                        className="post-thumbnail lazyloaded"
                        src={item.resized_featured_image || `https://tailnews.tailwindtemplate.net/src/img/dummy/img1.jpg`}
                      />
                    </a>
                  </div>
                  <div className="fbt-post-caption card-body">
                    <h3 className="post-title h4 card-title">
                      <a href={`/posts/${item.slug}/`}>
                        {item.title}
                      </a>
                    </h3>
                    <div className="post-meta">
                      <span className="post-author">
                        <a href="#">{item.feeder.name}</a>
                      </span>
                      <span className="post-date published">{item.pub_date}</span>
                    </div>
                    <p className="post-excerpt card-text">
                      {item.excerpt}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          {hasMore && <div className="blog-pager" id="blog-pager">
            <div className="list-inline">
              <a
                className="blog-pager-older-link list-inline-item pointer"
                title="More posts"
                onClick={handleLoadMore}
              >
                <div className="fbt-bp-message text-uppercase font-weight-bold">
                  More posts
                </div>
                <ArrowDown className="justify-center inline-flex"></ArrowDown>
              </a>
            </div>
          </div>}
          
        </div>
      </div>
    </div>
  );
}

export function Today() {
  return (
    <>
      <SiteHeader></SiteHeader>
      <div id="page-wrapper" className="full-page feed-view">
        <div className="outer-wrapper clearfix" id="outer-wrapper">
          <div className="xl:container mx-auto px-3 sm:px-4 xl:px-2">
            <div className="row justify-content-center">

              <SecondNews></SecondNews>
            </div>
          </div>
        </div>
      </div>

      {/* <LoadMode></LoadMode> */}

      <hr></hr>
      {/* <Separator></Separator> */}
      <Footer></Footer>
      <a
        href="#"
        className="back-top fixed p-4 rounded bg-gray-100 border border-gray-100 text-gray-500 dark:bg-gray-900 dark:border-gray-800 right-4 bottom-4 block"
        aria-label="Scroll To Top"
      >
        <svg
          width="1rem"
          height="1rem"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 01.5.5v9a.5.5 0 01-1 0V4a.5.5 0 01.5-.5z"
            clipRule="evenodd"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.646 2.646a.5.5 0 01.708 0l3 3a.5.5 0 01-.708.708L8 3.707 5.354 6.354a.5.5 0 11-.708-.708l3-3z"
            clipRule="evenodd"
          ></path>
        </svg>
      </a>
    </>
  );
}
